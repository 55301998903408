
import './send.css'
import { useNavigate } from "react-router-dom";

const Send = () => {
    const navigate = useNavigate();

    window.Telegram.WebApp.BackButton.onClick(() => {
        navigate('/')
       });
    
    
    return (
      <div className='main'>
      
      <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path class="line" d="M2,12 L9,19 L22,6" />
        </svg>
        <div className='text'>Спасибо! Данные успешно отправлены</div>
  </div>
    )
}

export default Send;