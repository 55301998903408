import {Link , useNavigate} from "react-router-dom"
import './policy.css'

const Policy = () => {

    const navigate = useNavigate();

    window.Telegram.WebApp.BackButton.onClick(() => {
        navigate('/')
       });

    return(
        <div className="policy">
        <div className="text">
            <div className='header'>
            Политика обработки персональных данных</div>
        <p>
             Предоставляя свои персональные данные Пользователь даёт согласие на&nbsp;обработку, хранение и&nbsp;использование своих персональных данных на&nbsp;основании ФЗ №&nbsp;<nobr>152-ФЗ</nobr> «О&nbsp;персональных данных» от&nbsp;27.07.2006&nbsp;г.&nbsp;в&nbsp;следующих целях:
        </p>
        <ul>
            <li>Осуществление клиентской поддержки</li>
            <li>Получения Пользователем информации о&nbsp;маркетинговых событиях</li>
            <li>Проведения аудита и&nbsp;прочих внутренних исследований с&nbsp;целью повышения качества предоставляемых услуг.</li>
        </ul>
        <p>
             Под персональными данными подразумевается любая информация личного характера, позволяющая установить личность Пользователя/Покупателя такая как:
        </p>
        <ul>
            <li>Фамилия, Имя</li>
            <li>Контактный телефон</li>
        </ul>
        <p>
             Персональные данные Пользователей хранятся исключительно на&nbsp;электронных носителях и&nbsp;обрабатываются с&nbsp;использованием автоматизированных систем, за&nbsp;исключением случаев, когда неавтоматизированная обработка персональных данных необходима в&nbsp;связи с&nbsp;исполнением требований законодательства.
        </p>
        <p>
             Компания обязуется не&nbsp;передавать полученные персональные данные третьим лицам, за&nbsp;исключением следующих случаев:
        </p>
        <ul>
            <li>По&nbsp;запросам уполномоченных органов государственной власти&nbsp;РФ только по&nbsp;основаниям и&nbsp;в&nbsp;порядке, установленным законодательством РФ</li>
            <li>Стратегическим партнерам, которые работают с&nbsp;Компанией для предоставления продуктов и&nbsp;услуг, или тем из&nbsp;них, которые помогают Компании реализовывать продукты и&nbsp;услуги потребителям. Мы&nbsp;предоставляем третьим лицам минимальный объем персональных данных, необходимый только для оказания требуемой услуги или проведения необходимой транзакции.</li>
        </ul>
        <p>
             Компания оставляет за&nbsp;собой право вносить изменения в&nbsp;одностороннем порядке в&nbsp;настоящие правила, при условии, что изменения не&nbsp;противоречат действующему законодательству РФ. Изменения условий настоящих правил вступают в&nbsp;силу после их&nbsp;публикации в&nbsp;Приложении.
        </p>
    </div>
    </div>
    )
}

export default Policy