import './form.css';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom"
import { localUrl } from '../localSettings.js'
const Form = () => {
  const APIURL = localUrl.APIURL;
  const navigate = useNavigate();
  const [phone, setPhone] = useState('+7');

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    bonusNumber: NaN,
    receipt: NaN,
    receiptMarked: NaN
  });

  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    const { firstName, lastName, phone, bonusNumber, receipt } = formData;
    setIsFormValid(firstName.length > 0 && lastName.length > 0 && (phone.length > 10) && receipt && bonusNumber);
  }, [formData]);

  const handleChangePhone = (e) => {
    const input = e.target.value.replace(/\D/g, '');
    let formattedNumber = '';

    if (e.target.value.substring(0, 2) !== '+7') {
      formattedNumber = input
    }

    if (input.length === 1 && input === '7') {
      formattedNumber = '+7'
    }

    if (input.length === 2 && e.target.value.substring(0, 2) === '+7') {
      formattedNumber = '+7(' + input.substring(1, 2);
    }

    if (input.length === 3 && e.target.value.substring(0, 2) === '+7') {
      formattedNumber = '+7(' + input.substring(1, 3);
    }

    if (input.length === 4 && e.target.value.substring(0, 2) === '+7') {
      formattedNumber = '+7(' + input.substring(1, 4);
    }

    if (input.length === 5 && e.target.value.substring(0, 2) === '+7') {
      formattedNumber = '+7(' + input.substring(1, 4) + ')-' + input.substring(4, 5);
    }

    if (input.length === 6 && e.target.value.substring(0, 2) === '+7') {
      formattedNumber = '+7(' + input.substring(1, 4) + ')-' + input.substring(4, 6);
    }

    if (input.length === 7 && e.target.value.substring(0, 2) === '+7') {
      formattedNumber = '+7(' + input.substring(1, 4) + ')-' + input.substring(4, 7);
    }

    if (input.length === 8 && e.target.value.substring(0, 2) === '+7') {
      formattedNumber = '+7(' + input.substring(1, 4) + ')-' + input.substring(4, 7) + '-' + input.substring(7, 8);
    }

    if (input.length === 9 && e.target.value.substring(0, 2) === '+7') {
      formattedNumber = '+7(' + input.substring(1, 4) + ')-' + input.substring(4, 7) + '-' + input.substring(7, 9);
    }

    if (input.length === 10 && e.target.value.substring(0, 2) === '+7') {
      formattedNumber = '+7(' + input.substring(1, 4) + ')-' + input.substring(4, 7) + '-' + input.substring(7, 9) + '-' + input.substring(9, 10);
    }

    if (input.length === 11 && e.target.value.substring(0, 2) === '+7') {
      formattedNumber = '+7(' + input.substring(1, 4) + ')-' + input.substring(4, 7) + '-' + input.substring(7, 9) + '-' + input.substring(9, 11);
    }

    console.log(input)
    setPhone(formattedNumber)

    setFormData((prevData) => ({
      ...prevData,
      'phone': input
    }));

  };

  const handleChange = (e) => {
    console.log(e.target.value)
    setFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value
    }));
  };

  const clickPolicy = (e) => {
    e.preventDefault();
    navigate('/policy');
  }

  const handleSubmit = (e) => {
    console.log('trying to submit ', formData);
    fetch(APIURL + '/send-winter', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...formData, initData: window.Telegram.WebApp.initData })
    })
    //    navigate('/')
    navigate('/send', {
        replace: true,
        state: { sent: true }
    });
};

    console.log(formData)

  return (
    <>
      <div className="container">
        <div className="inputContainer">
          <h className='header'>Участвовать</h>
        </div>

        <div className="inputContainer">
          <div className="label">Ваше имя</div>
          <input className="input" type="text" name='firstName' onChange={handleChange}></input>
        </div>

        <div className="inputContainer">
          <div className="label">Ваша фамилия</div>
          <input className="input" type="text" name="lastName" onChange={handleChange}></input>
        </div>

        <div className="inputContainer">
          <div className="label">Номер телефона для связи</div>
          <input className="input"
            type="tel"
            inputMode="numeric"
            maxLength={17}
            onChange={handleChangePhone}
            value={phone}
          >
          </input>
        </div>

        <div className="inputContainer">
          <div className="label">Номер бонусной карты</div>
          <input className="input" type="number" inputmode="numeric" name="bonusNumber" onChange={handleChange}></input>
        </div>

        <div className="inputContainer">
          <div className="label">Номер чека (ОФД)</div>
          <input className="input" type="number" inputmode="numeric" name="receipt" onChange={handleChange}></input>
        </div>

        <div className="inputContainer">
          <div className="label">Номер чека (ОФД) с маркированным товаром (вода и газированные напитки, молочная продукция)</div>
          <input className="input" type="number" inputmode="numeric" name="receiptMarked" onChange={handleChange}></input>
        </div>

        <div className="inputContainer">
          <div className="label">&nbsp;</div>
          <button type="text" className='button' onClick={handleSubmit} disabled={!isFormValid}>Принять участие</button>
        </div>
        <div className="policyApprove">
          Заполняя форму, вы соглашаетесь&nbsp;
          <Link onClick={clickPolicy}>
            c политикой обработки персональных данных
          </Link>
        </div>
      </div>
    </>
  )
}

export default Form;